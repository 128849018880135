import merge from '~lodash.mergewith'
import { init } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F28dc42b6e6ea4b528318bb104d306c7d@o4504474234060800.ingest.sentry.io\u002F4504478251876352",
    environment:"production_nj",
    release:"1-8-4_nj",
    beforeSend:function (event, hint) {
        const error = hint.originalException;
        if (
        error instanceof Error && (
        error.message.match(/Load failed/i) ||
        error.message.match(/Failed to fetch/i) ||
        error.message.match(/AbortError/i) ||
        error.message.match(/The operation was aborted./i) ||
        error.message.match(/Failed to start the audio device/i) ||
        error.message.match(/NetworkError when attempting to fetch resource./i) ||
        error.message.match(/Connection to Indexed Database server lost./i)))
        {
          return null;
        }
        return event;
      },
  }

  config.integrations = [
    new ExtraErrorData(),
    new RewriteFrames(),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
